import React from 'react'
import PropTypes from 'prop-types'
import 'twin.macro'

const Subheader = ({ children }) => (
  <h2 tw="font-normal text-lg md:text-center max-w-screen-md md:mx-auto mx-8">
    {children}
  </h2>
)

Subheader.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Subheader
