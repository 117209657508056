import React from 'react'
import PropTypes from 'prop-types'
import 'twin.macro'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Subheader from '../components/Subheader'
import BlogItem from '../components/BlogItem'
import Head from '../components/Head'

dayjs.extend(isSameOrBefore)

const App = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Layout>
      <Head />
      <Container>
        <Subheader>
          Hier kom je alles te weten over mijn hobby, modelspoor. Op deze website deel ik de voortgang van de bouw van
          mijn modelspoor in schaal N (in 1:160).
        </Subheader>

        {posts
          .filter(({ node: post }) => post.frontmatter.title.length > 0
          && dayjs(post.frontmatter.date).isSameOrBefore(dayjs()))
          .map(
            ({ node: post }) => (
              <BlogItem
                title={post.frontmatter.title}
                status={post.frontmatter.status}
                summary={post.excerpt}
                path={post.frontmatter.path}
                createdAt={post.frontmatter.date}
              />
            ),
          )}
      </Container>
    </Layout>
  )
}

App.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape(),
      })),
    }),
  }).isRequired,
}

export default App

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            path
            date
            title
            status
          }
        }
      }
    }
  }
`
