import React from 'react'
import PropTypes from 'prop-types'
import 'twin.macro'
import dayjs from 'dayjs'
import 'dayjs/locale/nl'
import Header from './Header'
import BlogPostBody from './BlogPostBody'

const { NODE_ENV } = process.env

const BlogItem = ({
  title, summary, createdAt, status, path,
}) => {
  dayjs.locale('nl')

  if (NODE_ENV !== 'development' && status !== 'published') {
    return null
  }

  return (
    <article tw="xl:w-4/5 mx-auto mt-20">
      <a href={path} tw="block mt-4">
        <Header hidden={status !== 'published'}>
          {title}
        </Header>
        <BlogPostBody>{`<p>${summary}</p>`}</BlogPostBody>
      </a>
      <div tw="mt-6 flex items-center mx-8 md:mx-0">
        <div>
          <div tw="flex space-x-1 text-sm text-gray-600 dark:text-gray-400">
            <time dateTime={dayjs(createdAt).format('YYYY-MM-DD')}>{dayjs(createdAt).format('D MMMM YYYY')}</time>
          </div>
        </div>
      </div>
    </article>
  )
}

BlogItem.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
}

export default BlogItem
